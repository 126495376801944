import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import React from 'react';

import styled from '@emotion/styled';

export const StyledInput = styled(Input)`
  border: 0.5px solid white;
  color: white;
  font-size: 15px;
  width: 310px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
`;

export default function CustomInput({
  label,
  register,
  error,
  mb,
  ...inputProps
}) {
  return (
    <FormControl isInvalid={error} mb={mb}>
      <FormLabel color="#6D6D6E" htmlFor="name">
        {label}
      </FormLabel>
      <StyledInput
        border="0.5px solid #FFFFFF"
        ref={register}
        {...inputProps}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
}
