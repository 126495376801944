import React, { useContext, useState, useEffect } from 'react';
import {
  Table,
  Td,
  Th,
  Thead,
  Tbody,
  Tr,
  Text,
  VStack,
  useToast,
  Skeleton,
  SkeletonCircle,
} from '@chakra-ui/react';
import EditDetailsButton from '../molecules/EditDetailsButton';
import { ReactComponent as PopOver } from '../../assets/images/popover.svg';
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Bike } from '../../assets/images/bike.svg';
import { ReactComponent as CarYellow } from '../../assets/images/carYellow.svg';
import { ReactComponent as BikeYellow } from '../../assets/images/bikeYellow.svg';
import api from '../../config';
import { AuthContext } from '../../context/AuthContext';
import { DashboardContext } from '../../context/DashboardContext';
import AlertDialogBox from '../molecules/AlertDialogBox';

export default function CustomTable({ data }) {
  const [memberId, setMemberId] = useState();
  const [ownerTypes, setOwnerTypes] = useState();
  const { token } = useContext(AuthContext);
  const { fetchOwnersAndVehicles } = useContext(DashboardContext);
  const toast = useToast();

  function fetchOwnerTypes() {
    const options = {
      url: '/dashboard/types/',
      method: 'GET',
      headers: {
        Authorization: `token ${token}`,
      },
    };

    api(options)
      .then(response => {
        setOwnerTypes(response.data);
        let memberObj = response.data?.find(
          ownerObj => ownerObj.type.toLowerCase() === 'member'
        );
        setMemberId(memberObj.id);
      })
      .catch(error => {});
  }

  const getOwnerType = type => {
    let obj = ownerTypes?.find(ownerObj => ownerObj.id === type);
    return obj?.type;
  };

  useEffect(() => {
    fetchOwnerTypes();
  }, []);

  const handleDeleteOwner = id => {
    const options = {
      url: '/dashboard/owners/' + id + '/',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `token ${token}`,
      },
    };

    api(options)
      .then(response => {
        fetchOwnersAndVehicles();
        toast({
          title: 'Success',
          description: 'Owner Deleted',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(error => {});
  };
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Flat no.</Th>
          <Th>Name</Th>
          <Th>License Plate no.</Th>
          <Th>Type</Th>
          <Th></Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {data && data.results && memberId ? (
          data.results.map(row => (
            <Tr color={row.type === memberId ? 'black' : '#F5AD1B'}>
              <Td>
                {row.type === memberId
                  ? row?.flat_number
                  : getOwnerType(row?.type)}
              </Td>
              <Td>{row.name}</Td>

              <Td>
                <VStack
                  spacing="30px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {row.vehicles.map(vehicle => (
                    <Text>{vehicle.number_plate}</Text>
                  ))}
                </VStack>
              </Td>

              <Td>
                <VStack
                  spacing="20px"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {row.type === memberId &&
                    row.vehicles?.map(vehicle =>
                      vehicle?.type === 'Four Wheeler' ? <Car /> : <Bike />
                    )}

                  {row.type !== memberId &&
                    row.vehicles?.map(vehicle =>
                      vehicle?.type === 'Four Wheeler' ? (
                        <CarYellow />
                      ) : (
                        <BikeYellow />
                      )
                    )}
                </VStack>
              </Td>

              <Td w="160px" style={{ cursor: 'pointer' }}>
                <EditDetailsButton
                  title={<PopOver />}
                  ownerId={row.id}
                  vehicles={row.vehicles}
                  memberId={memberId}
                  ownerTypes={ownerTypes}
                />
              </Td>
              <Td>
                <AlertDialogBox
                  handleDeleteOwner={handleDeleteOwner}
                  id={row?.id}
                  name={row.name}
                />
              </Td>
            </Tr>
          ))
        ) : (
          <>
            {[...Array(16).keys()].map(x => (
              <Tr>
                <Td>
                  <Skeleton height="20px" w="150px" />
                </Td>
                <Td>
                  <Skeleton height="20px" w="150px" />
                </Td>

                <Td>
                  <Skeleton height="20px" w="150px" />
                </Td>

                <Td>
                  <Skeleton height="20px" w="150px" />
                </Td>

                <Td>
                  <SkeletonCircle w="6" h="6" />
                </Td>
                <Td>
                  <SkeletonCircle w="6" h="6" />
                </Td>
              </Tr>
            ))}
          </>
        )}
      </Tbody>
    </Table>
  );
}
