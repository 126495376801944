import { Button, HStack } from '@chakra-ui/react';
import React, { useContext } from 'react';
import api from '../../config';
import { AuthContext } from '../../context/AuthContext';

export default function DownloadLogs({ startDate, endDate }) {
  const { token } = useContext(AuthContext);

  const handleDownload = () => {
    api({
      url: '/dashboard/csv/download/',
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `token ${token}`,
      },
      params: {
        start_time: startDate,
        end_time: endDate,
      },
    }).then(response => {
      console.log(response, 'download data');
      console.log('response.headers', response.headers);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].slice(1,-1));
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <HStack justifyContent="flex-end" spacing="36px">
      <Button
        onClick={() => {
          handleDownload();
        }}
        bg="#5B4DBE"
        color="white"
        borderRadius="8px"
        px="20px"
      >
        Download logs
      </Button>
    </HStack>
  );
}
