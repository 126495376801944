// function EditableControls({ isEditing, onSubmit, onCancel, onEdit, state }) {
//   return isEditing ? (
//     <ButtonGroup justifyContent="center" size="sm">
//       <IconButton
//         isDisabled={state.length !== 10 ? true : false}
//         icon={<CheckIcon />}
//         onClick={() => {
//           if (state.length === 10) {
//             onSubmit();
//           }
//         }}
//       />
//       <IconButton
//         isDisabled={state.length !== 10 ? true : false}
//         icon={<CloseIcon />}
//         onClick={onCancel}
//       />
//     </ButtonGroup>
//   ) : (
//     <Flex justifyContent="center">
//       <IconButton size="sm" icon={<EditIcon />} onClick={onEdit} />
//     </Flex>
//   );
// }

// export default function CustomMobileEditable({
//   editable,
//   value,
//   w,
//   vehicleId,
// }) {
//   /* Here's a custom control */
//   const [state, setState] = useState(null);
//   const { token } = useContext(AuthContext);
//   const { fetchOwnersAndVehicles } = useContext(DashboardContext);

//   useEffect(() => {
//     setState(value);
//   }, []);

//   function onSave() {
//     var url = '/dashboard/vehicles/' + vehicleId?.toString() + '/';
//     const options = {
//       url: url,
//       method: 'PATCH',
//       headers: {
//         Authorization: `token ${token}`,
//       },
//       data: { number_plate: state },
//     };

//     api(options)
//       .then(response => {
//         fetchOwnersAndVehicles();
//       })
//       .catch(error => {});
//   }

//   const handleInputChange = val => {
//     console.log(val, 'val');
//     if (val.length < 11) {
//       setState(
//         val
//           .replace(/[^\w\s]/gi, '')
//           .replace(/_/g, '')
//           .replace(/\s/g, '')
//           .replace(/\D/g, '')
//           .toUpperCase()
//       );
//     }
//   };

//   return (
//     <Editable
//       value={state}
//       textAlign="center"
//       defaultValue={value}
//       fontSize="2xl"
//       isPreviewFocusable={false}
//       submitOnBlur={false}
//       onChange={val => handleInputChange(val)}
//       onSubmit={val => onSave()}
//     >
//       {props => (
//         <HStack>
//           <EditablePreview
//             w={w}
//             h="40px"
//             bg={editable ? 'white' : '#F4F4F4'}
//             borderRadius="12px"
//             color="#252733"
//             fontWeight="600"
//             fontSize="16px"
//             px="15px"
//             py="8px"
//           />
//           {editable ? (
//             <>
//               <EditableInput color="#252733" fontWeight="600" fontSize="16px" />
//               <EditableControls {...props} state={state} />
//             </>
//           ) : (
//             <></>
//           )}
//         </HStack>
//       )}
//     </Editable>
//   );
// }

import React, { useState, useEffect } from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons';

function EditableControls({ isEditing, onSubmit, onCancel, onEdit }) {
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} onClick={onSubmit} />
      <IconButton icon={<CloseIcon />} onClick={onCancel} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<EditIcon />} onClick={onEdit} />
    </Flex>
  );
}

export default function CustomEditable({
  editable,
  value,
  name,
  handleChange,
  w,
}) {
  /* Here's a custom control */
  const [state, setState] = useState(value);
  useEffect(() => {
    setState(value);
  }, []);
  return (
    <Editable
      value={state}
      textAlign="center"
      defaultValue={state}
      fontSize="2xl"
      isPreviewFocusable={false}
      submitOnBlur={false}
      onChange={val => {
        let processedVal = val
          .replace(/[^\w\s]/gi, '')
          .replace(/_/g, '')
          .replace(/\s/g, '')
          .replace(/\D/g, '')
          .toUpperCase();
        setState(processedVal);
      }}
      onSubmit={val => handleChange(name, val)}
    >
      {props => (
        <HStack>
          <EditablePreview
            w={w}
            h="40px"
            bg={editable ? 'white' : '#F4F4F4'}
            borderRadius="12px"
            color="#252733"
            fontWeight="600"
            fontSize="16px"
            px="15px"
            py="8px"
          />
          {editable ? (
            <>
              <EditableInput color="#252733" fontWeight="600" fontSize="16px" />
              <EditableControls {...props} />
            </>
          ) : (
            <></>
          )}
        </HStack>
      )}
    </Editable>
  );
}
