import React, { useState, createContext, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import api from '../config';
export const DashboardContext = createContext();

export const DashboardProvider = props => {
  const [data, setData] = useState(null);

  const [pageCount, setPageCount] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState('');

  const { token } = useContext(AuthContext);

  useEffect(() => {
    fetchOwnersAndVehicles();
  }, [currentPage]);

  const fetchOwnersAndVehicles = async () => {
    try {
      var url =
        '/dashboard/owners/?page=' +
        currentPage.toString() +
        '&' +
        'query=' +
        searchQuery;
      const result = await api.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });

      setData(result.data);
      var count = result.data.count / 20;
      var roundOff = Math.ceil(count);
      setPageCount(roundOff);
    } catch (error) {}
  };

  return (
    <DashboardContext.Provider
      value={{
        data,
        setData,
        setPageCount,
        fetchOwnersAndVehicles,
        pageCount,
        setCurrentPage,
        searchQuery,
        setSearchQuery,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};
