import React, { useState, useContext, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  useToast,
  useDisclosure,
  VStack,
  Text,
  HStack,
  Link,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import api from '../../config';
//context
import { AuthContext } from '../../context/AuthContext';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg';
import { ReactComponent as UploadedIcon } from '../../assets/images/uploaded.svg';

function UploadModal() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const onFileUpload = event => {
    setSelectedFile(event.target.files[0]);
  };

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const onSubmitUpload = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('name', 'myfile');
    const options = {
      url: '/dashboard/csv/upload/',
      method: 'POST',
      headers: {
        Authorization: `token ${token}`,
      },
      data: formData,
    };

    api(options)
      .then(response => {
        setLoading(false);
        setSelectedFile(null);
        toast({
          title: 'Uploaded Successful.',
          description: response.data.detail,
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(error => {
        setLoading(false);
        setSelectedFile(null);
        toast({
          title: 'An error occurred.',
          description: error?.response?.data?.detail,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#5B4DBE"
        color="white"
        borderRadius="8px"
        px="20px"
      >
        Upload data
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          setSelectedFile(null);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody bg="#F2F2F2" py="55px" w="600px" borderRadius="8px">
            <VStack spacing="17px">
              <>
                {selectedFile ? (
                  <VStack w="368px" h="152px" pt="45px">
                    <HStack spacing="20px">
                      <UploadedIcon />
                      <Text>{selectedFile.name}</Text>
                      <SmallCloseIcon
                        color="#e53e3e"
                        w={8}
                        h={8}
                        cursor="pointer"
                        onClick={() => setSelectedFile(null)}
                      />
                    </HStack>
                  </VStack>
                ) : (
                  <>
                    <VStack
                      spacing="17px"
                      border="#BDBDBD dotted 2px"
                      w="368px"
                      h="152px"
                      pt="45px"
                      cursor="pointer"
                      onClick={handleClick}
                      {...getRootProps()}
                    >
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={hiddenFileInput}
                        onChange={onFileUpload}
                        {...getInputProps()}
                      />
                      <Text fontWeight="500" fontSize="16px" color="#BDBDBD">
                        Drag files here or click to browse
                      </Text>
                      <UploadIcon />
                    </VStack>
                  </>
                )}
              </>
              <HStack spacing="50px">
                <Button
                  bg="#5B4DBE"
                  color="white"
                  borderRadius="8px"
                  px="10px"
                  h="28px"
                  fontSize="15px"
                  fontWeight="400"
                  onClick={onSubmitUpload}
                  isDisabled={selectedFile ? false : true}
                  isLoading={loading ? true : false}
                  loadingText="Uploading"
                >
                  Upload CSV
                </Button>
                <Button
                  bg="#5B4DBE"
                  color="white"
                  borderRadius="8px"
                  px="10px"
                  h="28px"
                  fontSize="15px"
                  fontWeight="400"
                >
                  <Link
                    href="https://drive.google.com/drive/folders/1od448C0f0kjA8oq2fAcSfjk7tBJPKVk9?usp=sharing"
                    isExternal
                  >
                    Sample CSV
                  </Link>
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default UploadModal;
