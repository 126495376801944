import React from 'react';
import { HStack, Text, VStack, Grid } from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Bike } from '../../assets/images/bike.svg';
import CustomVehicleEditable from './CustomVehicleEditable';

export default function UpdateVehicle({ vehicles, handleDeleteOwner }) {
  return (
    <>
      {vehicles?.length > 0 ? (
        <>
          <HStack justifyContent="flex-start" w="100%">
            <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
              Vehicles
            </Text>
          </HStack>

          <VStack w="100%">
            {vehicles &&
              vehicles?.map(vehicle => {
                return (
                  <Grid
                    templateColumns="0.3fr 0.7fr 0.3fr"
                    rowGap="20px"
                    justifyContent="flex-start"
                    alignItems="center"
                    w="100%"
                  >
                    {vehicle.type === 'Four Wheeler' ? <Car /> : <Bike />}
                    <CustomVehicleEditable
                      name="number_plate"
                      value={vehicle.number_plate}
                      editable={true}
                      onCheckClick
                      vehicleId={vehicle.id}
                    />
                    <DeleteIcon
                      cursor="pointer"
                      onClick={() => handleDeleteOwner(vehicle.id)}
                    />
                  </Grid>
                );
              })}
          </VStack>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
