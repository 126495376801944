import React from 'react';
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg';
import {
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import EditVehicleModal from './EditVehicleModal';

export default function EditDetailsButton({ ownerId, memberId, ownerTypes }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <VStack>
      <>
        <InfoIcon onClick={onOpen} />

        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
          }}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalBody
              bg="#F2F2F2"
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
              borderRadius="8px"
            >
              <EditVehicleModal
                ownerTypes={ownerTypes}
                memberId={memberId}
                onClose={onClose}
                ownerId={ownerId}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </VStack>
  );
}
