import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  useToast,
  ModalBody,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { AuthContext } from '../../context/AuthContext';
import { ReactComponent as MenuIcon } from '../../assets/images/popover.svg';
import api from '../../config';

export default function DashboardPage() {
  const [isLoading, setLoading] = useState(false);
  const { handleLogout, token } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, errors, register, getValues } = useForm();
  const toast = useToast();

  function validateName(value) {
    if (!value) {
      return 'required';
    } else return true;
  }

  function validateNewPassword(value) {
    let formValuesObj = getValues();
    let newPassword = formValuesObj.new_password;
    if (!value) {
      return 'required';
    } else if (value !== newPassword) {
      return 'password do not match';
    } else return true;
  }

  function onSubmit(values) {
    let formValues = getValues();
    let stripedPasswordObj = { old_password: '', new_password: '' };
    stripedPasswordObj.old_password = formValues.old_password;
    stripedPasswordObj.new_password = formValues.retype_new_password;
    onPasswordReset(stripedPasswordObj);
  }

  function onPasswordReset(passwordObj, resolve, reject) {
    setLoading(true);
    var url = '/auth/resetpassword';
    const options = {
      url: url,
      method: 'POST',
      headers: {
        Authorization: `token ${token}`,
      },
      data: passwordObj,
    };
    api(options)
      .then(response => {
        setLoading(false);
        toast({
          title: 'Success',
          description: response.data.message,
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        onClose();
      })
      .catch(error => {
        setLoading(false);
        toast({
          title: 'An error occurred.',
          description: error.response.data.error,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      });
  }

  return (
    <>
      <Menu>
        <MenuButton as={Button}>
          <MenuIcon />
        </MenuButton>
        <MenuList>
          <MenuItem color="#646466" onClick={onOpen}>
            Change Password
          </MenuItem>
          <MenuItem color="#646466" onClick={handleLogout}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
      <>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <VStack>
                  <Text
                    mb="20px"
                    color="#646466"
                    fontSize="20px"
                    fontWeight="600"
                  >
                    Change password
                  </Text>
                  <FormControl isInvalid={errors.old_password}>
                    <HStack justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="name" color="#9FA2B4">
                        Current Password
                      </FormLabel>
                      <VStack>
                        <Input
                          name="old_password"
                          ref={register({ validate: validateName })}
                          w="200px"
                          border="none"
                          bg="#f8f8f8"
                          autocomplete="off"
                        />
                        <FormErrorMessage>
                          {errors.old_password && errors.old_password.message}
                        </FormErrorMessage>
                      </VStack>
                    </HStack>
                  </FormControl>
                  <FormControl isInvalid={errors.new_password}>
                    <HStack justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="new_password" color="#9FA2B4">
                        New Password
                      </FormLabel>
                      <VStack>
                        <Input
                          name="new_password"
                          ref={register({ validate: validateName })}
                          w="200px"
                          bg="#f8f8f8"
                          border="none"
                          type="password"
                        />
                        <FormErrorMessage>
                          {errors.new_password && errors.new_password.message}
                        </FormErrorMessage>
                      </VStack>
                    </HStack>
                  </FormControl>
                  <FormControl isInvalid={errors.retype_new_password}>
                    <HStack justifyContent="space-between" alignItems="center">
                      <FormLabel htmlFor="retype_new_password" color="#9FA2B4">
                        Retype new password
                      </FormLabel>
                      <VStack>
                        <Input
                          name="retype_new_password"
                          ref={register({ validate: validateNewPassword })}
                          w="200px"
                          bg="#f8f8f8"
                          border="none"
                          type="password"
                        />
                        <FormErrorMessage>
                          {errors.retype_new_password &&
                            errors.retype_new_password.message}
                        </FormErrorMessage>
                      </VStack>
                    </HStack>
                  </FormControl>
                  <HStack justifyContent="center">
                    <Button
                      mt={4}
                      isLoading={isLoading}
                      type="submit"
                      bg="#5B4DBE"
                      borderRadius="5px"
                      color="white"
                      w="120px"
                    >
                      Save
                    </Button>
                  </HStack>
                </VStack>
              </form>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    </>
  );
}
