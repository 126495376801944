import React, { useEffect, useContext } from 'react';
import { VStack, Image, Text } from '@chakra-ui/react';
//assets
import navLogo from '../../assets/images/navLogo.svg';
import societyIcon from '../../assets/images/society.png';
import dashboard from '../../assets/images/dashboard.svg';
import statistics from '../../assets/images/statistics.svg';
import styled from '@emotion/styled';
//router
import { Link, withRouter } from 'react-router-dom';
//config
import api from '../../config';
//context
import { AuthContext } from '../../context/AuthContext';

const StyledText = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  color: white;
`;

const StyledMenu = styled(VStack)`
  cursor: pointer;
`;
function Navbar(props) {
  const { pathname } = props.location;
  const { token, society, setSociety } = useContext(AuthContext);

  const fetchSociety = async () => {
    let url = '/dashboard/societies/';
    try {
      const result = await api.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      localStorage.setItem('society', result.data[0]);

      setSociety(result.data[0]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchSociety();
  }, []);

  return (
    <VStack bg="#262628" maxW="130px">
      <Image src={navLogo} alt="" mb="20px" mt="25px" />

      <Link to="/">
        <VStack>
          <Image src={societyIcon} alt="" />
          <StyledText mb="160px">{society?.name}</StyledText>
        </VStack>
      </Link>

      <Link to="/">
        <StyledMenu mb="80px">
          <Image src={dashboard} alt="" />
          {pathname === '/' ? <StyledText>Home</StyledText> : ''}
        </StyledMenu>
      </Link>

      <Link to="/statistics">
        <StyledMenu>
          <Image src={statistics} alt="" />
          {pathname === '/statistics' ? (
            <StyledText>Statistics</StyledText>
          ) : (
            ''
          )}
        </StyledMenu>
      </Link>
    </VStack>
  );
}

export default withRouter(Navbar);
