import React, { useState, useContext } from 'react';
import RadioButton from '../atoms/CustomRadioButton';
import {
  Button,
  HStack,
  Text,
  VStack,
  Grid,
  Input,
  useRadioGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Bike } from '../../assets/images/bike.svg';
import api from '../../config';
//context
import { AuthContext } from '../../context/AuthContext';

function AddVehicleModal({ ownerId, fetchData }) {
  const [vehicleType, setVehicleType] = useState();
  const [numberPlate, setNumberPlate] = useState('');
  const { token } = useContext(AuthContext);
  const options = ['Four Wheeler', 'Two Wheeler'];
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: val => setVehicleType(val),
  });

  const group = getRootProps();

  const handleNumberPlate = val => {
    setNumberPlate(
      val
        .replace(/[^\w\s]/gi, '')
        .replace(/_/g, '')
        .replace(/\s/g, '')
        .toUpperCase()
    );
  };

  function onAdd() {
    const options = {
      url: '/dashboard/vehicles/',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `token ${token}`,
      },
      data: {
        number_plate: numberPlate,
        type: vehicleType,
        owner: ownerId,
      },
    };

    api(options)
      .then(response => {
        toast({
          title: 'Success',
          status: 'success',
          isClosable: true,
        });
        onClose();
        fetchData();
      })
      .catch(error => {
        var errorString = '';
        for (const [key, value] of Object.entries(error?.response.data)) {
          var errorField = key;
          var errorMessage = value;
          var final = errorField + ' :- ' + errorMessage;
          errorString = errorString + ' ' + final;
        }
        toast({
          title: 'An error occurred.',
          description: errorString,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      });
  }

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#5B4DBE"
        borderRadius="5px"
        type="submit"
        color="white"
      >
        Add Vehicle
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            w="500px"
            bg="#F2F2F2"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
            borderRadius="8px"
          >
            <VStack spacing="40px" alignItems="center">
              <Text fontWeight="700" fontSize="16px" color="#646466">
                Edit details
              </Text>
              <Grid
                templateColumns="175px 155px"
                templateRows="1fr 1fr"
                columnGap="100px"
              >
                <HStack justifyContent="center">
                  <Text color="#9FA2B4" fontWeight="bold" fontSize="16px">
                    Select type of vehicle
                  </Text>
                </HStack>

                <HStack justifyContent="center">
                  <Text color="#9FA2B4" fontWeight="bold" fontSize="16px">
                    License Plate no.
                  </Text>
                </HStack>

                <HStack {...group} justifyContent="space-between">
                  {options.map(value => {
                    const radio = getRadioProps({ value });
                    return (
                      <RadioButton key={value} {...radio}>
                        {value === 'Four Wheeler' ? <Car /> : <Bike />}
                      </RadioButton>
                    );
                  })}
                </HStack>
                <Input
                  bg="white"
                  borderRadius="12px"
                  color="#252733"
                  fontWeight="600"
                  fontSize="16px"
                  px="15px"
                  py="8px"
                  value={numberPlate}
                  onChange={e => handleNumberPlate(e.target.value)}
                />
              </Grid>
              <Button
                onClick={() => {
                  onAdd();
                }}
                bg="#5B4DBE"
                borderRadius="5px"
                type="submit"
                color="white"
              >
                Save
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddVehicleModal;
