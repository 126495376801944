import React, { useState, useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Paginator,
  Previous,
  Next,
  PageGroup,
  Container,
} from 'chakra-paginator';

import { DashboardContext } from '../../context/DashboardContext';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const Pagination = ({ pageCount }) => {
  const { setCurrentPage } = useContext(DashboardContext);

  // react hooks
  const [isPaginatorDisabled, setIsPaginatorDisabled] = useState(false);

  // constants
  const pagesQuantity = pageCount; // -> calculated or obtained from Backend
  const outerLimit = 2;
  const innerLimit = 2;

  // styles
  const baseStyles = {
    w: 7,
    fontSize: 'sm',
    bg: '#FFFFFF',
  };

  const normalStyles = {
    ...baseStyles,
    border: '1px solid #DFE3E8',
  };

  const activeStyles = {
    ...baseStyles,
    color: '#4200FF',
    border: '1px solid #4200FF',
  };

  const separatorStyles = {
    ...baseStyles,
    border: '1px solid #DFE3E8',
    w: 7,
  };

  // handlers
  const handlePageChange = currentPage => {
    // -> request new data using the page number

    setCurrentPage(currentPage);
  };

  return (
    <ChakraProvider>
      <Paginator
        isDisabled={isPaginatorDisabled}
        activeStyles={activeStyles}
        innerLimit={innerLimit}
        outerLimit={outerLimit}
        normalStyles={normalStyles}
        separatorStyles={separatorStyles}
        pagesQuantity={pagesQuantity}
        onPageChange={handlePageChange}
      >
        <Container align="center" justify="space-between" w="full" p={4}>
          <Previous bg="#919EAB">
            <FiChevronLeft />
            {/* Or an icon from `react-icons` */}
          </Previous>
          <PageGroup isInline align="center" />
          <Next bg="#FFFFFF" border="1px solid #DFE3E8">
            <FiChevronRight />
            {/* Or an icon from `react-icons` */}
          </Next>
        </Container>
      </Paginator>
    </ChakraProvider>
  );
};

export default Pagination;
