import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CustomInput from '../atoms/CustomInput';
import CustomForm from '../atoms/CustomForm';
import { AuthContext } from '../../context/AuthContext';
import api from '../../config';

const schema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().required(),
});

export default function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const {
    username,
    password,
    handleLoginInput,
    setIsAuthenticated,
    setToken,
    setCurrentUser,
  } = useContext(AuthContext);
  const { handleSubmit, errors, register } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  function onSubmit() {
    setIsLoading(true);
    const options = {
      url: '/auth/login',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      data: { username: username, password: password },
    };

    api(options)
      .then(response => {
        setIsLoading(false);
        if (response.data.token) {
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('currentUser', response.data.username);
          localStorage.setItem('isAuthenticated', true);
          setToken(response.data.token);
          setCurrentUser(response.data.username);
          setIsAuthenticated(true);
        }
      })
      .catch(error => {
        setIsLoading(false);
        let loginError = 'Opps!';

        if (error.message === 'Network Error') {
          loginError = 'Network Error';
        } else if (error.message === 'Request failed with status code 404') {
          loginError = 'user not found';
        } else {
          loginError = 'Opps Error occured!';
        }
        toast({
          title: 'An error occurred.',
          description: loginError,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  }

  return (
    <CustomForm handleSubmit={handleSubmit} onSubmit={onSubmit}>
      <Text fontSize="24px" color="white" mb="25px">
        Login
      </Text>
      <CustomInput
        label="Email Address"
        name="email"
        placeholder="youremail@gmail.com"
        register={register}
        error={errors.email}
        mb="15px"
        onChange={handleLoginInput}
      />
      <CustomInput
        label="Enter PIN"
        type="password"
        name="password"
        placeholder="Entry PIN"
        register={register}
        error={errors.password}
        mb="55px"
        onChange={handleLoginInput}
      />
      <Button
        bg="#5B4DBE"
        borderRadius="5px"
        isLoading={isLoading}
        type="submit"
        color="white"
      >
        Login
      </Button>
    </CustomForm>
  );
}
