import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';

export default function CustomBarGraph({ data }) {
  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: '#9593A0', fontSize: '9px' }}>{value}</span>;
  };
  return (
    <BarChart
      width={474}
      height={178}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <XAxis
        minTickGap={50}
        axisLine={false}
        tickLine={false}
        dataKey="timestamp"
        style={{ fontSize: '10px' }}
      />
      <YAxis minTickGap={20} style={{ fontSize: '10px' }} />

      <Tooltip />
      <Legend formatter={renderColorfulLegendText} />
      <Bar dataKey="authorized" stackId="a" fill="#C8B5FB" />
      <Bar dataKey="unauthorized" stackId="a" fill="#E8E0FE" />
    </BarChart>
  );
}
