import React, { useState } from 'react';
import RadioButton from '../atoms/CustomRadioButton';
import {
  Button,
  HStack,
  Text,
  VStack,
  Grid,
  useRadioGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
} from '@chakra-ui/react';
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Bike } from '../../assets/images/bike.svg';
import OwnerFormInput from '../atoms/OwnerFormInput';

function AddNewOwnerVehicle({ ownerInfo, setOwnerInfo }) {
  const [vehicleType, setVehicleType] = useState();
  const [numberPlate, setNumberPlate] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const options = ['Four Wheeler', 'Two Wheeler'];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'react',
    onChange: val => setVehicleType(val),
  });

  const group = getRootProps();

  function onAdd() {
    let tempVehicleObj = {
      number_plate: numberPlate,
      type: vehicleType,
    };
    let tempOwnerInfo = JSON.parse(JSON.stringify(ownerInfo));
    tempOwnerInfo.vehicles.push(tempVehicleObj);
    setOwnerInfo(tempOwnerInfo);
    onClose();
  }

  const handleNumberPlate = val => {
    setNumberPlate(
      val
        .replace(/[^\w\s]/gi, '')
        .replace(/_/g, '')
        .replace(/\s/g, '')
        .toUpperCase()
    );
  };

  return (
    <>
      <Button onClick={onOpen} bg="#5B4DBE" color="white">
        Add Vehicle
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            w="500px"
            bg="#F2F2F2"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
            borderRadius="8px"
          >
            <VStack spacing="40px" alignItems="center">
              <Text fontWeight="700" fontSize="16px" color="#646466">
                Edit details
              </Text>
              <Grid
                templateColumns="175px 155px"
                templateRows="1fr 1fr"
                columnGap="100px"
              >
                <HStack justifyContent="center">
                  <Text color="#9FA2B4" fontWeight="bold" fontSize="16px">
                    Select type of vehicle
                  </Text>
                </HStack>

                <HStack justifyContent="center">
                  <Text color="#9FA2B4" fontWeight="bold" fontSize="16px">
                    License Plate no.
                  </Text>
                </HStack>

                <HStack {...group} justifyContent="space-between">
                  {options.map(value => {
                    const radio = getRadioProps({ value });
                    return (
                      <RadioButton key={value} {...radio}>
                        {value === 'Four Wheeler' ? <Car /> : <Bike />}
                      </RadioButton>
                    );
                  })}
                </HStack>
                <OwnerFormInput
                  name="numberPlate"
                  value={numberPlate}
                  onChange={e => handleNumberPlate(e.target.value)}
                />
              </Grid>
              <Button
                onClick={onAdd}
                bg="#5B4DBE"
                borderRadius="5px"
                type="submit"
                color="white"
                isDisabled={
                  numberPlate?.length > 0 && vehicleType?.length > 0
                    ? false
                    : true
                }
              >
                Save
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddNewOwnerVehicle;
