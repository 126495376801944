import React from 'react';
import { XAxis, YAxis, Tooltip, Legend, LineChart, Line } from 'recharts';

export default function CustomLineGraph({ dataOne }) {
  const renderColorfulLegendText = (value, entry) => {
    return <span style={{ color: '#9593A0', fontSize: '9px' }}>{value}</span>;
  };
  return (
    <LineChart
      width={474}
      height={178}
      data={dataOne}
      margin={{ top: 5, right: 10, left: 10, bottom: 5 }}
    >
      <XAxis hide={false} dataKey="timestamp" style={{ fontSize: '10px' }} />
      <YAxis minTickGap={50} axisLine={false} tickLine={false} />
      <Tooltip />
      <Legend formatter={renderColorfulLegendText} />
      <Line type="monotone" dataKey="authorized" stroke="#FD9CCA" />
      <Line type="monotone" dataKey="unauthorized" stroke="#855CF8" />
    </LineChart>
  );
}
