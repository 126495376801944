import React from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons';

function EditableControls({ isEditing, onSubmit, onCancel, onEdit }) {
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} onClick={onSubmit} />
      <IconButton icon={<CloseIcon />} onClick={onCancel} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<EditIcon />} onClick={onEdit} />
    </Flex>
  );
}

export default function CustomEditable({
  editable,
  value,
  name,
  handleChange,
  w,
}) {
  /* Here's a custom control */

  return (
    <Editable
      textAlign="center"
      defaultValue={value}
      fontSize="2xl"
      isPreviewFocusable={false}
      submitOnBlur={false}
      onSubmit={val => handleChange(name, val)}
    >
      {props => (
        <HStack>
          <EditablePreview
            w={w}
            bg={editable ? 'white' : '#F4F4F4'}
            borderRadius="12px"
            color="#252733"
            fontWeight="600"
            fontSize="16px"
            px="15px"
            py="8px"
          />
          {editable ? (
            <>
              <EditableInput color="#252733" fontWeight="600" fontSize="16px" />
              <EditableControls {...props} />
            </>
          ) : (
            <></>
          )}
        </HStack>
      )}
    </Editable>
  );
}
