import { HStack } from '@chakra-ui/react';
import React, { useEffect, useContext } from 'react';
import CustomBarGraph from '../atoms/CustomBarGraph';
import useWebSocket from 'react-use-websocket';
import CustomLineGraph from '../atoms/CustomLineGraph';
import { AuthContext } from '../../context/AuthContext';
import { WS_BASE_URL } from '../../constants';

export default function BarAndLineGraphContainer({
  graphType,
  startDate,
  endDate,
}) {
  const { society } = useContext(AuthContext);
  useEffect(() => {
    handle();
  }, [startDate, endDate]);

  const socketUrl = WS_BASE_URL + '/vehicle_traffic_trend/' + society?.id;
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    shouldReconnect: closeEvent => true,
  });

  const handle = () => {
    sendJsonMessage({
      start_ts: startDate,
      end_ts: endDate,
    });
  };
  return (
    <>
      {graphType === 'bar' ? (
        lastJsonMessage && lastJsonMessage.constructor === Array ? (
          <HStack m="10px">
            <CustomBarGraph data={lastJsonMessage} />
          </HStack>
        ) : (
          <div>loading</div>
        )
      ) : lastJsonMessage && lastJsonMessage.constructor === Array ? (
        <HStack m="10px">
          <CustomLineGraph dataOne={lastJsonMessage} />
        </HStack>
      ) : (
        <div>loading</div>
      )}
    </>
  );
}
