import React, { useContext, useEffect } from 'react';
import "./app.css"
import { ChakraProvider, Box, theme, Grid } from '@chakra-ui/react';
//pages
import LoginPage from './components/pages/LoginPage';
import DashboardPage from './components/pages/DashboardPage';
import StatisticsPage from './components/pages/StatisticsPage';
//components
import Navbar from './components/molecules/Navbar';
//router
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './auth/protected.route';
//context
import { AuthContext } from './context/AuthContext';

function App() {
  const {
    isAuthenticated,
    setToken,
    setCurrentUser,
    setIsAuthenticated,
    setSociety,
  } = useContext(AuthContext);
  useEffect(() => {
    const token = localStorage.getItem('token');
    setToken(token);
    const currentUser = localStorage.getItem('currentUser');
    setCurrentUser(currentUser);
    const isAuthenticated = JSON.parse(localStorage.getItem('isAuthenticated'));
    setIsAuthenticated(isAuthenticated);
    const society = localStorage.getItem('society');
    setSociety(society);
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Box h="100vh">
        <Switch>
          {console.log(isAuthenticated,"isAuthenticated")}
          <Route
            exact
            path="/login"
            render={() =>
              isAuthenticated ? <Redirect to="/" /> : <LoginPage />
            }
          />
          <Grid templateColumns="auto 1fr" h="100vh">
              <Navbar />
            <Box padding="30px 45px 10px 50px" overflow="auto">
              <ProtectedRoute exact path="/" component={DashboardPage} />
              <ProtectedRoute
                exact
                path="/statistics"
                component={StatisticsPage}
              />
            </Box>
          </Grid>
        </Switch>
      </Box>
    </ChakraProvider>
  );
}

export default App;
