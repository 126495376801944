import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';

function AlertDialogBox({ handleDeleteOwner, id, name }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  return (
    <>
      <DeleteIcon cursor="pointer" onClick={() => setIsOpen(true)} />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogBody py="45px">
              {`Are you sure you want to remove ${name} from the member’s list?`}
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="space-between">
              <Button
                px="40px"
                h="2rem"
                bg="white"
                color="#5B4DBE"
                border=" 1px solid #5B4DBE"
                borderRadius="8px"
                ref={cancelRef}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                px="40px"
                h="2rem"
                bg="#5B4DBE"
                color="white"
                borderRadius="8px"
                onClick={() => {
                  onClose();
                  handleDeleteOwner(id);
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default AlertDialogBox;
