import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  HStack,
  Text,
  Input,
  VStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import CustomDatePicker from '../atoms/CustomDatePicker';
import PieChartsContainer from '../molecules/PieChartsContainer';
import DownloadLogs from '../molecules/DownloadLogs';
import BarAndLineGraphContainer from '../molecules/BarAndLineGraphContainer';
import CustomMenu from '../molecules/CustomMenu';

export const StyledInput = styled(Input)`
  background-color: #f3f4f6;
  border-radius: 12px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #646466;
  }
  :-ms-input-placeholder {
    color: #646466;
  }
`;

export default function StatisticsPage() {
  const [startDate, setMainStartDate] = useState();
  const [endDate, setMainEndDate] = useState();
  const [startTime, setMainStarttime] = useState('00:00:00');
  const [endTime, setMainEndTime] = useState('00:00:00');

  const handleStartDate = date => {
    setMainStartDate(date);
  };

  const handleEndDate = date => {
    setMainEndDate(date);
  };

  const handleStartTime = time => {
    setMainStarttime(time);
  };

  const handleEndTime = time => {
    setMainEndTime(time);
  };

  function convertDate() {
    var date = new Date(),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    var finaldate = [date.getFullYear(), mnth, day].join('-');
    return finaldate;
  }

  useEffect(() => {
    setMainStartDate(convertDate());
    setMainEndDate(convertDate());
    console.log('lol hehe');
  }, []);

  return (
    <Box>
      <Grid templateRows="1fr auto" rowGap="20px" mb="20px">
        <HStack height="18px" justifyContent="center">
          <Text
            lineHeight="0.9"
            color="#262628"
            fontWeight="600"
            fontSize="24px"
          >
            Statistics
          </Text>
        </HStack>

        <HStack spacing="30px" justifyContent="flex-end" alignItems="flex-end">
          <DownloadLogs
            startDate={[startDate, startTime].join(' ')}
            endDate={[endDate, endTime].join(' ')}
          />

          <CustomDatePicker
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            handleStartTime={handleStartTime}
            handleEndTime={handleEndTime}
          />

          <CustomMenu />
        </HStack>
      </Grid>

      <Box p="20px 45px">
        <VStack spacing="50px">
          <Box
            h="290px"
            borderRadius="10px"
            border=" 1px solid #D8D8D8"
            p="10px"
          >
            <Text>Visitor information</Text>
            <PieChartsContainer
              startDate={[startDate, startTime].join(' ')}
              endDate={[endDate, endTime].join(' ')}
            />
          </Box>
          <HStack spacing="45px">
            <Box
              h="244px"
              w="514px"
              borderRadius="10px"
              border=" 1px solid #D8D8D8"
              p="10px"
            >
              <Text>Traffic Details</Text>
              <BarAndLineGraphContainer
                graphType="bar"
                startDate={[startDate, startTime].join(' ')}
                endDate={[endDate, endTime].join(' ')}
              />
            </Box>
            <Box
              h="244px"
              w="514px"
              borderRadius="10px"
              border=" 1px solid #D8D8D8"
              p="10px"
            >
              <Text>Vehicular trend</Text>
              <BarAndLineGraphContainer
                graphType="line"
                startDate={[startDate, startTime].join(' ')}
                endDate={[endDate, endTime].join(' ')}
              />
            </Box>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
}
