import React from 'react';
import { Cell, PieChart, Pie, Label } from 'recharts';

const func = (props, labelArray) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, value, name, index } =
    props;
  const RADIAN = Math.PI / 180;
  // eslint-disable-next-line
  const radius = 10 + innerRadius + (outerRadius - innerRadius);
  // eslint-disable-next-line
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  // eslint-disable-next-line
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#9593A0"
      style={{ fontWeight: '700', fontSize: '9px' }}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {labelArray[index]}: {value}
    </text>
  );
};

function CustomLabel(props) {
  const { cx, cy } = props.viewBox;
  return (
    <>
      <text
        x={cx}
        y={cy - 5}
        fill="rgba(0, 0, 0, 0.87)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan
          alignmentBaseline="middle"
          fontSize="10px"
          fontWeight="700"
          fill="#9593A0"
        >
          {`${props.value1}:`}
        </tspan>
      </text>
      <text
        x={cx}
        y={cy + 20}
        fill="rgba(0, 0, 0, 0.54)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan fontSize="16px" fontWeight="700" fill="#2a2a2a">
          {props.value2}
        </tspan>
      </text>
    </>
  );
}

export default function CustomPieChart({
  data01,
  total,
  colors,
  labelArray,
  totalValueLabel,
}) {
  console.log(data01, 'data01');
  return (
    <PieChart width={350} height={200}>
      <Pie
        key={Math.random()}
        stroke="none"
        dataKey="value"
        nameKey="name"
        cx="100px"
        cy="50%"
        innerRadius={65}
        outerRadius={80}
        fill="fill"
        data={data01}
        labelLine={false}
        label={props => func(props, labelArray)}
      >
        {data01.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index]} />
        ))}
        <Label
          width={30}
          style={{ fontSize: '8px' }}
          position="center"
          content={<CustomLabel value1={totalValueLabel} value2={total} />}
        ></Label>
      </Pie>
    </PieChart>
  );
}
