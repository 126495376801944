import React, { useState, createContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [society, setSociety] = useState(null);

  const handleLoginInput = event => {
    if (event.target.name === 'email') {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const handleLogout = () => {
    localStorage.setItem('token', null);
    localStorage.setItem('currentUser', null);
    localStorage.setItem('isAuthenticated', false);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        password,
        handleLoginInput,
        isAuthenticated,
        setIsAuthenticated,
        handleLogout,
        setToken,
        setCurrentUser,
        token,
        currentUser,
        society,
        setSociety,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
