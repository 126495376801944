import { InputGroup, InputRightElement, Input } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { Search2Icon } from '@chakra-ui/icons';

import { DashboardContext } from '../../context/DashboardContext';

export default function SearchComponent() {
  const { setSearchQuery, fetchOwnersAndVehicles, setCurrentPage } = useContext(
    DashboardContext
  );

  const handleInput = event => {
    setSearchQuery(event.target.value);
  };

  const handleKeypress = e => {
    if (e.key === 'Enter') {
      setCurrentPage(1);
      fetchOwnersAndVehicles();
    }
  };

  return (
    <InputGroup w="210px">
      <Input
        onChange={handleInput}
        variant="filled"
        placeholder="Search"
        onKeyPress={handleKeypress}
      />

      <InputRightElement
        onClick={() => {
          setCurrentPage(1);
          fetchOwnersAndVehicles();
        }}
        cursor="pointer"
        children={<Search2Icon />}
      />
    </InputGroup>
  );
}
