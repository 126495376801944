import React, { useState, useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  useToast,
  Button,
} from '@chakra-ui/react';
import { ReactComponent as AddIcon } from '../../assets/images/add.svg';
import AddOwner from './AddOwner';
import { AuthContext } from '../../context/AuthContext';
import { DashboardContext } from '../../context/DashboardContext';
import api from '../../config';

function AddOwnerandVehicleModal() {
  const [isLoading, setIsLoading] = useState(false);
  const [building, setBuilding] = useState('');
  const [flatNumber, setFlatNumber] = useState('');
  const [ownerInfo, setOwnerInfo] = useState({
    flat_number: '',
    phone_number: '',
    type: null,
    society: null,
    name: '',
    vehicles: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { token, society } = useContext(AuthContext);
  const { fetchOwnersAndVehicles } = useContext(DashboardContext);
  const toast = useToast();

  const handleInputChange = event => {
    console.log('handlechange');
    if (ownerInfo.society === null) {
      setOwnerInfo(prevState => ({
        ...prevState,
        society: society?.id,
      }));
      console.log('yes society was null but I think its not nowe');
    } else {
      console.log(society);
    }
    var value;
    if (event.target.name === 'type') {
      value = Number(event.target.value);
    } else {
      value = event.target.value;
    }
    setOwnerInfo(prevState => ({
      ...prevState,
      [event.target.name]: value,
    }));
  };

  const handleBuildingInputChange = event => {
    var value = event.target.value;
    setBuilding(value);
    setOwnerInfo(prevState => ({
      ...prevState,
      flat_number: value + '-' + flatNumber,
    }));
  };

  const handleFlatNumberInputChange = event => {
    var value = event.target.value;
    setFlatNumber(value);
    setOwnerInfo(prevState => ({
      ...prevState,
      flat_number: building + value,
    }));
  };

  const handleEditVehicle = (event, index) => {
    let tempOwnerInfo = JSON.parse(JSON.stringify(ownerInfo));
    tempOwnerInfo.vehicles[index].number_plate = event.target.value;
    setOwnerInfo(tempOwnerInfo);
  };

  const handleDeleteVehicle = index => {
    let tempOwnerInfo = JSON.parse(JSON.stringify(ownerInfo));
    tempOwnerInfo.vehicles.splice(index, 1);
    setOwnerInfo(tempOwnerInfo);
  };

  useEffect(() => {
    setOwnerInfo(prevState => ({
      ...prevState,
      society: society?.id,
    }));
  }, []);

  function onOwnerSave() {
    setIsLoading(true);
    var url = '/dashboard/owners/';

    const options = {
      url: url,
      method: 'POST',
      headers: {
        Authorization: `token ${token}`,
      },
      data: ownerInfo,
    };

    api(options)
      .then(response => {
        setIsLoading(false);
        fetchOwnersAndVehicles();
        toast({
          title: 'Owner created.',
          description: "We've created owner for you.",
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setOwnerInfo({
          flat_number: '',
          phone_number: '',
          type: null,
          society: null,
          name: '',
          vehicles: [],
        });
        if (!isLoading) {
          onClose();
        }
      })
      .catch(error => {
        setIsLoading(false);
        var errorString = '';
        for (const [key, value] of Object.entries(error?.response.data)) {
          var errorField = key;
          var errorMessage = value;
          var final = errorField + ' :- ' + errorMessage;
          errorString = errorString + ' ' + final;
        }
        toast({
          title: 'An error occurred.',
          description: errorString,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      });
  }

  return (
    <>
      <Button borderRadius="12px" onClick={onOpen}>
        <AddIcon />
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody
            w="500px"
            bg="#F2F2F2"
            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.15)"
            borderRadius="8px"
          >
            <AddOwner
              isLoading={isLoading}
              ownerInfo={ownerInfo}
              setOwnerInfo={setOwnerInfo}
              onOwnerSave={onOwnerSave}
              handleInputChange={handleInputChange}
              handleEditVehicle={handleEditVehicle}
              handleDeleteVehicle={handleDeleteVehicle}
              handleBuildingInputChange={handleBuildingInputChange}
              handleFlatNumberInputChange={handleFlatNumberInputChange}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddOwnerandVehicleModal;
