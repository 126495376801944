import { Center, Grid, Image, VStack } from '@chakra-ui/react';
import React from 'react';
//assets
import loginLogo from '../../assets/images/loginLogo.svg';
import guard from '../../assets/images/guard.svg';
//components
import LoginForm from '../organisms/LoginForm';

export default function LoginPage() {
  return (
    <Grid templateColumns="1fr 1fr" h="100vh">
      <VStack spacing="90px">
        <Image src={loginLogo} alt="" mt="45px" />
        <Image src={guard} alt="" />
      </VStack>
      <Center bg="#262628">
        <LoginForm />
      </Center>
    </Grid>
  );
}
