import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  HStack,
  VStack,
  Text,
  Select,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Grid,
} from '@chakra-ui/react';
import { ReactComponent as Car } from '../../assets/images/car.svg';
import { ReactComponent as Bike } from '../../assets/images/bike.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import styled from '@emotion/styled';
import api from '../../config';
import { AuthContext } from '../../context/AuthContext';
import { useForm } from 'react-hook-form';
import AddNewOwnerVehicle from './AddNewOwnerVehicle';

export const StyledInput = styled(Input)`
  border: none;
  color: black;
  background-color: white;
  font-size: 15px;
  border-radius: 12px;
  width: ${props => props.w};
`;

export default function AddOwner({
  isLoading,
  ownerInfo,
  onOwnerSave,
  setOwnerInfo,
  handleInputChange,
  handleEditVehicle,
  handleDeleteVehicle,
  handleBuildingInputChange,
  handleFlatNumberInputChange,
}) {
  const [ownerTypes, setOwnerTypes] = useState(null);
  const [memberId, setMemberId] = useState();
  const { token } = useContext(AuthContext);

  const { handleSubmit, errors, register } = useForm();

  function fetchOwnerTypes() {
    const options = {
      url: '/dashboard/types/',
      method: 'GET',
      headers: {
        Authorization: `token ${token}`,
      },
    };

    api(options)
      .then(response => {
        setOwnerTypes(response.data);
        let memberObj = response.data?.find(
          ownerObj => ownerObj.type.toLowerCase() === 'member'
        );
        setMemberId(memberObj.id);
      })
      .catch(error => {});
  }

  useEffect(() => {
    fetchOwnerTypes();
  }, []);

  function validateName(value) {
    if (!value) {
      return 'required';
    } else return true;
  }

  function validateNumber(value) {
    if (!value) {
      return 'required';
    } else if (value.length !== 10) {
      return '10 digit number';
    } else return true;
  }

  const onSubmit = () => {
    onOwnerSave();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="40px" alignItems="center">
        <Text fontWeight="700" fontSize="16px" color="#646466">
          Add new member Details
        </Text>

        <FormControl
          isInvalid={errors.name}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <VStack w="100%">
            <Grid
              templateColumns="0.3fr 1fr"
              rowGap="20px"
              justifyContent="flex-start"
              alignItems="center"
              w="100%"
            >
              <FormLabel
                color="#9FA2B4"
                fontWeight="bold"
                fontSize="14px"
                htmlFor="name"
              >
                First name
              </FormLabel>
              <StyledInput
                name="name"
                onChange={handleInputChange}
                ref={register({ validate: validateName })}
                w="155px"
              />
            </Grid>
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </VStack>
        </FormControl>

        <FormControl
          isInvalid={errors.type}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <VStack w="100%">
            <Grid
              templateColumns="0.3fr 1fr"
              rowGap="20px"
              justifyContent="flex-start"
              alignItems="center"
              w="100%"
            >
              <FormLabel
                color="#9FA2B4"
                fontWeight="bold"
                fontSize="14px"
                htmlFor="name"
              >
                Owner type
              </FormLabel>

              <Select
                w="155px"
                name="type"
                placeholder="Select"
                bg="white"
                borderRadius="12px"
                onChange={handleInputChange}
                ref={register({ validate: validateName })}
              >
                {ownerTypes?.map(ownerType => (
                  <option
                    selected={ownerInfo.type === ownerType.id ? true : false}
                    value={ownerType.id}
                  >
                    {ownerType.type}
                  </option>
                ))}
              </Select>
            </Grid>
            <FormErrorMessage>
              {errors.type && errors.type.message}
            </FormErrorMessage>
          </VStack>
        </FormControl>

        {ownerInfo.type === memberId ? (
          <VStack spacing="10px" w="100%">
            <FormControl
              isInvalid={errors.building}
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="center"
              w="100%"
            >
              <VStack w="100%">
                <Grid
                  templateColumns="0.3fr 1fr"
                  rowGap="20px"
                  justifyContent="flex-start"
                  alignItems="center"
                  w="100%"
                >
                  <FormLabel
                    color="#9FA2B4"
                    fontWeight="bold"
                    fontSize="14px"
                    htmlFor="name"
                    margin="0"
                  >
                    Building
                  </FormLabel>
                  <StyledInput
                    name="building"
                    onChange={handleBuildingInputChange}
                    w="155px"
                    ref={register({ validate: validateName })}
                  />
                </Grid>
                <FormErrorMessage>
                  {errors.building && errors.building.message}
                </FormErrorMessage>
              </VStack>
            </FormControl>

            <FormControl
              isInvalid={errors.flat_number}
              display="flex"
              direction="row"
              alignItems="center"
              justifyContent="center"
              w="100%"
            >
              <VStack w="100%">
                <Grid
                  templateColumns="0.3fr 1fr"
                  rowGap="20px"
                  justifyContent="flex-start"
                  alignItems="center"
                  w="100%"
                >
                  <FormLabel
                    color="#9FA2B4"
                    fontWeight="bold"
                    fontSize="14px"
                    htmlFor="name"
                    margin="0"
                  >
                    Flat number
                  </FormLabel>
                  <StyledInput
                    name="flat_number"
                    onChange={handleFlatNumberInputChange}
                    w="155px"
                    ref={register({ validate: validateName })}
                  />
                </Grid>
                <FormErrorMessage>
                  {errors.flat_number && errors.flat_number.message}
                </FormErrorMessage>
              </VStack>
            </FormControl>
          </VStack>
        ) : (
          <></>
        )}

        <FormControl
          isInvalid={errors.phone_number}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <VStack w="100%">
            <Grid
              templateColumns="0.3fr 1fr"
              rowGap="20px"
              justifyContent="flex-start"
              alignItems="center"
              w="100%"
            >
              <FormLabel
                color="#9FA2B4"
                fontWeight="bold"
                fontSize="14px"
                htmlFor="name"
              >
                Mobile no.
              </FormLabel>
              <StyledInput
                name="phone_number"
                onChange={handleInputChange}
                w="155px"
                ref={register({ validate: validateNumber })}
              />
            </Grid>
            <FormErrorMessage>
              {errors.phone_number && errors.phone_number.message}
            </FormErrorMessage>
          </VStack>
        </FormControl>

        {ownerInfo.vehicles.length > 0 ? (
          <>
            <HStack w="100%" justifyContent="flex-start">
              <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
                Vehicles -
              </Text>
            </HStack>

            <VStack w="100%">
              {ownerInfo.vehicles?.map((vehicle, index) => {
                return (
                  <Grid
                    templateColumns="0.3fr 0.7fr 0.3fr"
                    justifyContent="flex-start"
                    alignItems="center"
                    w="100%"
                  >
                    {vehicle.type === 'Four Wheeler' ? <Car /> : <Bike />}
                    <StyledInput
                      w="155px"
                      name="number_plate"
                      value={vehicle.number_plate}
                      editable={true}
                      onChange={event => handleEditVehicle(event, index)}
                    />
                    <DeleteIcon
                      onClick={() => handleDeleteVehicle(index)}
                      cursor="pointer"
                    />
                  </Grid>
                );
              })}
            </VStack>
          </>
        ) : (
          <></>
        )}

        <HStack w="100%" justifyContent="space-between" px="30px">
          <AddNewOwnerVehicle
            ownerInfo={ownerInfo}
            setOwnerInfo={setOwnerInfo}
          />
          <Button
            isLoading={isLoading}
            bg="#5B4DBE"
            borderRadius="5px"
            type="submit"
            color="white"
            w="120px"
          >
            Save
          </Button>
        </HStack>
      </VStack>
    </form>
  );
}
