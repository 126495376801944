import React, { useState } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import calenar from '../../assets/images/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker-styles.css';
import { Button, Text, Grid } from '@chakra-ui/react';

const customStyles = {
  content: {
    top: '40%',
    left: '89%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
  },
};

function CustomDatePicker({
  handleStartDate,
  handleEndDate,
  handleStartTime,
  handleEndTime,
}) {
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  function convertDate(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    var finaldate = [date.getFullYear(), mnth, day].join('-');
    return finaldate;
  }
  function convertTime(str) {
    var time = new Date(str),
      hours = ('0' + time.getHours()).slice(-2),
      minutes = ('0' + time.getMinutes()).slice(-2),
      seconds = ('0' + time.getSeconds()).slice(-2);
    var finalTime = [hours, minutes, seconds].join(':');
    return finalTime;
  }
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    var tempStart = convertDate(start);
    var tempEnd = convertDate(end);
    handleStartDate(tempStart);
    handleEndDate(tempEnd);
  };

  const onStartTimeChange = time => {
    var tempStartTime = convertTime(time);
    setStartTime(time);
    handleStartTime(tempStartTime);
  };
  const onEndTimeChange = time => {
    var tempEndTime = convertTime(time);

    setEndTime(time);
    handleEndTime(tempEndTime);
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <Button onClick={openModal} bg="transparent">
        <img src={calenar} alt="pick date" />
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
        />
        <Grid
          templateColumns="0.3fr 1fr"
          bg="white"
          px="10px"
          py="10px"
          w="245px"
          borderRadius="10px"
          justifyContent="flex-start"
          alignItems="center"
          mt="20px"
          mb="10px"
          background="#f8f8f8"
        >
          <Text mr="5px" fontSize="10px">
            From
          </Text>
          <DatePicker
            selected={startTime}
            onChange={time => onStartTimeChange(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Grid>
        <Grid
          templateColumns="0.3fr 1fr"
          bg="white"
          w="245px"
          px="10px"
          py="10px"
          borderRadius="10px"
          justifyContent="flex-start"
          alignItems="center"
          background="#f8f8f8"
        >
          <Text mr="5px" fontSize="10px">
            To
          </Text>
          <DatePicker
            selected={endTime}
            onChange={time => onEndTimeChange(time)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
          />
        </Grid>
      </Modal>
    </div>
  );
}

export default CustomDatePicker;
