import { HStack, SkeletonCircle, VStack, Flex } from '@chakra-ui/react';
import React, { useEffect, useContext } from 'react';
import CustomPieChart from '../atoms/CustomPieChart';
import useWebSocket from 'react-use-websocket';
import { AuthContext } from '../../context/AuthContext';
import { WS_BASE_URL } from '../../constants';

const PieChartsContainer = ({ startDate, endDate }) => {
  var pieChartOneColors = ['#5b4dbe', '#e5e2f4'];
  var pieChartTwoColors = ['#f12b2c', '#fde4e4'];
  const { society } = useContext(AuthContext);
  useEffect(() => {
    handle();
  }, [startDate, endDate]);

  const socketUrl = WS_BASE_URL + '/visitor_info/' + society?.id;

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    shouldReconnect: closeEvent => true,
  });

  const handle = () => {
    sendJsonMessage({
      start_ts: startDate,
      end_ts: endDate,
    });
  };

  return (
    <>
      {lastJsonMessage && lastJsonMessage.constructor === Array ? (
        <VStack>
          <HStack px="0px" spacing="10px" m="10px">
            <CustomPieChart
              data01={[lastJsonMessage[2], lastJsonMessage[3]]}
              total={lastJsonMessage[0]?.value}
              colors={pieChartOneColors}
              uniqueNumberPlates={lastJsonMessage[1]?.value}
              labelArray={['Authorized vehicles', 'Visitors']}
              totalValueLabel="Total Vehicles"
            />
            {console.log(lastJsonMessage, 'lastJsonMessage')}
            <CustomPieChart
              data01={[lastJsonMessage[5], lastJsonMessage[6]]}
              total={lastJsonMessage[4]?.value}
              colors={pieChartTwoColors}
              uniqueNumberPlates={lastJsonMessage[1]?.value}
              labelArray={['Entry', 'Exit']}
              totalValueLabel="Total Visitor Vehicles"
            />
          </HStack>
          <Flex
            color="#9593A0"
            fontSize="13px"
            justifyContent="center"
            fontWeight="bold"
          >
            {`Number of unique number plates detected: ${lastJsonMessage[1]?.value}`}
          </Flex>
        </VStack>
      ) : (
        <HStack px="0px" spacing="150px" m="10px">
          <SkeletonCircle w="200px" h="200px" />
          <SkeletonCircle w="200px" h="200px" />
        </HStack>
      )}
    </>
  );
};

export default PieChartsContainer;
