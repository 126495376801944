// import React, { useContext, useState, useEffect } from 'react';
// import {
//   Editable,
//   EditableInput,
//   EditablePreview,
//   ButtonGroup,
//   Flex,
//   HStack,
//   IconButton,
//   useToast,
// } from '@chakra-ui/react';
// import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons';
// import api from '../../config';
// import { AuthContext } from '../../context/AuthContext';
// import { DashboardContext } from '../../context/DashboardContext';

// function EditableControls({ isEditing, onSubmit, onCancel, onEdit, state }) {
//   const toast = useToast();
//   return isEditing ? (
//     <ButtonGroup justifyContent="center" size="sm">
//       <IconButton
//         icon={<CheckIcon />}
//         onClick={() => {
//           if (state.length === 10) {
//             onSubmit();
//           } else {
//             toast({
//               title: 'Number Plate length should be 10',
//               status: 'error',
//               isClosable: true,
//             });
//           }
//         }}
//       />
//       <IconButton
//         isDisabled={state.length !== 10 ? true : false}
//         icon={<CloseIcon />}
//         onClick={onCancel}
//       />
//     </ButtonGroup>
//   ) : (
//     <Flex justifyContent="center">
//       <IconButton size="sm" icon={<EditIcon />} onClick={onEdit} />
//     </Flex>
//   );
// }

// export default function CustomVehicleEditable({
//   editable,
//   value,
//   w,
//   vehicleId,
// }) {
//   /* Here's a custom control */
//   const [state, setState] = useState(null);
//   const { token } = useContext(AuthContext);
//   const { fetchOwnersAndVehicles } = useContext(DashboardContext);

//   useEffect(() => {
//     setState(value);
//   }, []);

//   function onSave() {
//     var url = '/dashboard/vehicles/' + vehicleId.toString() + '/';
//     const options = {
//       url: url,
//       method: 'PATCH',
//       headers: {
//         Authorization: `token ${token}`,
//       },
//       data: { number_plate: state },
//     };

//     api(options)
//       .then(response => {
//         fetchOwnersAndVehicles();
//       })
//       .catch(error => {});
//   }

//   const handleInputChange = val => {
//     setState(
//       val
//         .replace(/[^\w\s]/gi, '')
//         .replace(/_/g, '')
//         .replace(/\s/g, '')
//         .toUpperCase()
//     );
//   };

//   return (
//     <Editable
//       value={state}
//       textAlign="center"
//       defaultValue={value}
//       fontSize="2xl"
//       isPreviewFocusable={false}
//       submitOnBlur={false}
//       onChange={val => handleInputChange(val)}
//       onSubmit={val => onSave()}
//     >
//       {props => (
//         <HStack>
//           <EditablePreview
//             w={w}
//             h="40px"
//             bg={editable ? 'white' : '#F4F4F4'}
//             borderRadius="12px"
//             color="#252733"
//             fontWeight="600"
//             fontSize="16px"
//             px="15px"
//             py="8px"
//           />
//           {editable ? (
//             <>
//               <EditableInput color="#252733" fontWeight="600" fontSize="16px" />
//               <EditableControls {...props} state={state} />
//             </>
//           ) : (
//             <></>
//           )}
//         </HStack>
//       )}
//     </Editable>
//   );
// }

import React, { useContext, useState, useEffect } from 'react';
import {
  Editable,
  EditableInput,
  EditablePreview,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { CheckIcon, EditIcon, CloseIcon } from '@chakra-ui/icons';
import api from '../../config';
import { AuthContext } from '../../context/AuthContext';
import { DashboardContext } from '../../context/DashboardContext';

function EditableControls({ isEditing, onSubmit, onCancel, onEdit }) {
  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} onClick={onSubmit} />
      <IconButton icon={<CloseIcon />} onClick={onCancel} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<EditIcon />} onClick={onEdit} />
    </Flex>
  );
}

export default function CustomEditable({ editable, value, w, vehicleId }) {
  /* Here's a custom control */
  const [state, setState] = useState(value);
  const toast = useToast();
  useEffect(() => {
    setState(value);
  }, []);

  const { token } = useContext(AuthContext);
  const { fetchOwnersAndVehicles } = useContext(DashboardContext);

  useEffect(() => {
    setState(value);
  }, []);

  function onSave() {
    var url = '/dashboard/vehicles/' + vehicleId.toString() + '/';
    const options = {
      url: url,
      method: 'PATCH',
      headers: {
        Authorization: `token ${token}`,
      },
      data: { number_plate: state },
    };

    api(options)
      .then(response => {
        toast({
          title: 'Success',
          description: 'Vehicle Updated',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        fetchOwnersAndVehicles();
      })
      .catch(error => {
        var errorString = '';
        for (const [key, value] of Object.entries(error?.response.data)) {
          var errorField = key;
          var errorMessage = value;
          var final = errorField + ' :- ' + errorMessage;
          errorString = errorString + ' ' + final;
        }
        toast({
          title: 'An error occurred.',
          description: errorString,
          status: 'error',
          duration: null,
          isClosable: true,
        });
      });
  }

  return (
    <Editable
      value={state}
      textAlign="center"
      defaultValue={state}
      fontSize="2xl"
      isPreviewFocusable={false}
      submitOnBlur={false}
      onChange={val => {
        let processedVal = val
          .replace(/[^\w\s]/gi, '')
          .replace(/_/g, '')
          .replace(/\s/g, '')
          .toUpperCase();
        setState(processedVal);
      }}
      onSubmit={val => onSave()}
    >
      {props => (
        <HStack>
          <EditablePreview
            w={w}
            h="40px"
            bg={editable ? 'white' : '#F4F4F4'}
            borderRadius="12px"
            color="#252733"
            fontWeight="600"
            fontSize="16px"
            px="15px"
            py="8px"
          />
          {editable ? (
            <>
              <EditableInput color="#252733" fontWeight="600" fontSize="16px" />
              <EditableControls {...props} />
            </>
          ) : (
            <></>
          )}
        </HStack>
      )}
    </Editable>
  );
}
