import {
  FormControl,
  FormErrorMessage,
  Input,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

import styled from '@emotion/styled';

export const StyledInput = styled(Input)`
  border: none;
  color: black;
  background-color: white;
  font-size: 15px;
  border-radius: 12px;
  width: ${props => props.w};
`;

export default function OwnerFormInput({
  w,
  register,
  error,
  label,
  ...inputProps
}) {
  return (
    <FormControl isInvalid={error}>
      <VStack>
        <HStack spacing="10px">
          <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
            {label}
          </Text>

          <StyledInput
            w={w}
            border="0.5px solid #FFFFFF"
            ref={register}
            {...inputProps}
          />
        </HStack>
        <HStack alignItems="flex-end">
          <FormErrorMessage>{error && error.message}</FormErrorMessage>
        </HStack>
      </VStack>
    </FormControl>
  );
}
