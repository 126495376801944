import React, { useContext, useEffect } from 'react';
import { Box, Grid, HStack, Text, Input } from '@chakra-ui/react';
import styled from '@emotion/styled';
import CustomTable from '../organisms/CustomTable';
import Pagination from '../molecules/Pagination';
import { DashboardContext } from '../../context/DashboardContext';
import UploadModal from '../molecules/UploadModal';
import SearchComponent from '../organisms/SearchComponent';
import AddOwnerandVehicleModal from '../molecules/AddOwnerandVehicleModal';
import CustomMenu from '../molecules/CustomMenu';

export const StyledInput = styled(Input)`
  background-color: #f3f4f6;
  border-radius: 12px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #646466;
  }
  :-ms-input-placeholder {
    color: #646466;
  }
`;

export default function DashboardPage() {
  const { data, fetchOwnersAndVehicles, pageCount } = useContext(
    DashboardContext
  );

  useEffect(() => {
    fetchOwnersAndVehicles();
  }, []);

  return (
    <Box>
      <Grid templateRows="1fr 150px auto" m="0px 65px">
        <HStack height="18px" justifyContent="center">
          <Text
            lineHeight="0.9"
            color="#262628"
            fontWeight="600"
            fontSize="24px"
          >
            Home
          </Text>
        </HStack>
        <HStack justifyContent="space-between" mt="30px">
          <Text color="#262628" fontWeight="700" fontSize="18px">
            Members’ List
          </Text>
          <HStack spacing="36px">
            <AddOwnerandVehicleModal />
            <SearchComponent />
            <UploadModal />
            <CustomMenu />
          </HStack>
        </HStack>
      </Grid>
      <Box p="20px 45px">
        <CustomTable data={data} />
      </Box>
      <Pagination pageCount={pageCount} />
    </Box>
  );
}
