import React, { useState, useEffect, useContext } from 'react';
import { Button, HStack, Text, VStack, useToast, Grid } from '@chakra-ui/react';
import CustomEditable from '../atoms/CustomEditable';
import api from '../../config';
//context
import { AuthContext } from '../../context/AuthContext';
import { DashboardContext } from '../../context/DashboardContext';
import UpdateVehicle from './UpdateVehicle';
import AddVehicleModal from './AddVehicleModal';
import CustomMobileEditable from './CustomMobileEditable';

function EditVehicleModal({ onClose, ownerId, memberId, ownerTypes }) {
  const [state, setState] = useState(null);
  const [stateVehicles, setVehiclesState] = useState(null);
  const { fetchOwnersAndVehicles } = useContext(DashboardContext);
  const { token } = useContext(AuthContext);
  const toast = useToast();
  var url = '/dashboard/owners/' + ownerId.toString() + '/';

  const fetchData = async () => {
    try {
      const result = await api.get(url, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      console.log(result.data, 'edit data');
      delete result.data.id;
      setVehiclesState(result.data.vehicles);
      delete result.data.vehicles;

      setState(result.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  function onSave() {
    var url = '/dashboard/owners/' + ownerId.toString() + '/';
    const options = {
      url: url,
      method: 'PATCH',
      headers: {
        Authorization: `token ${token}`,
      },
      data: state,
    };

    api(options)
      .then(response => {
        fetchOwnersAndVehicles();
      })
      .catch(error => {});
  }

  const handleChange = (name, value) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNumberChange = (name, value) => {
    if (!Number(value)) {
      return;
    }
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDeleteOwner = id => {
    const options = {
      url: '/dashboard/vehicles/' + id + '/',
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: `token ${token}`,
      },
    };

    api(options)
      .then(response => {
        fetchData();
        fetchOwnersAndVehicles();
        toast({
          title: 'Success',
          description: 'Vehicle Deleted',
          status: 'success',
          duration: 1000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: 'An error occurred.',
          description: 'Unable to create user account.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };

  const findOwnerTypefromId = () => {
    let ownerTypeObj = ownerTypes?.find(typeObj => typeObj.id === state.type);
    return ownerTypeObj.type;
  };

  if (state === null) return <div>Loading..</div>;

  return (
    <VStack spacing="40px" alignItems="center">
      <Text fontWeight="700" fontSize="16px" color="#646466">
        Edit details
      </Text>

      <Grid
        templateColumns="0.3fr 1fr"
        rowGap="20px"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
      >
        <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
          Name
        </Text>
        <CustomEditable
          name="name"
          value={state.name}
          editable={true}
          handleChange={handleChange}
          width="auto"
        />
      </Grid>
      {memberId === state.type ? (
        <Grid
          templateColumns="0.3fr 1fr"
          rowGap="20px"
          justifyContent="flex-start"
          alignItems="center"
          w="100%"
        >
          <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
            Flat number
          </Text>
          <CustomEditable
            name="flat_number"
            value={state.flat_number}
            editable={false}
            handleChange={handleChange}
          />
        </Grid>
      ) : (
        <Grid
          templateColumns="0.3fr 1fr"
          rowGap="20px"
          justifyContent="flex-start"
          alignItems="center"
          w="100%"
        >
          <Text color="#9FA2B4" fontWeight="bold" fontSize="14px">
            Owner Type
          </Text>
          <CustomEditable
            name="flat_number"
            value={findOwnerTypefromId()}
            editable={false}
            handleChange={handleChange}
          />
        </Grid>
      )}

      <Grid
        templateColumns="0.3fr 1fr"
        rowGap="20px"
        justifyContent="flex-start"
        alignItems="center"
        w="100%"
      >
        <Text color="#9FA2B4" fontWeight="bold" fontSize="14px" mt="10px">
          Mobile no.
        </Text>
        <CustomMobileEditable
          name="phone_number"
          value={state.phone_number}
          editable={true}
          handleChange={handleNumberChange}
        />
      </Grid>

      <UpdateVehicle
        handleDeleteOwner={handleDeleteOwner}
        vehicles={stateVehicles}
      />

      <HStack w="100%" justifyContent="space-between" px="30px">
        <AddVehicleModal ownerId={ownerId} fetchData={fetchData} />

        <Button
          onClick={() => {
            onSave();
            onClose();
            fetchOwnersAndVehicles();
          }}
          bg="#5B4DBE"
          borderRadius="5px"
          type="submit"
          color="white"
        >
          Save
        </Button>
      </HStack>
    </VStack>
  );
}

export default EditVehicleModal;
